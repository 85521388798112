<template>
	<div>
		<div class="page-section page-head">
			<div class="user-info">
				<img :src="userInfo.storeLogo" @error="imageLoadError" alt="" class="pic">
				<div class="info-main">
					<div class="name">{{userInfo.userName}}</div>
					<div class="text">
						<span>{{$t('liveplatform.home.id')}}:{{userInfo.userId}}</span>
						<span>{{$t('liveplatform.home.fans')}}:{{userInfo.followers}}</span>
					</div>
				</div>
			</div>
			<div class="bar">
				<el-button type="livebtn" @click="$router.push({name: 'LiveCreate'})" round>{{$t('liveplatform.home.createBtn')}}</el-button>
			</div>
		</div>
		<div class="page-section">
			<div class="section-top">
				<router-link :to="{name: 'DataPerformance'}" class="title">{{$t('liveplatform.home.pageTitle1')}} <i class="el-icon-arrow-right"></i></router-link>
			</div>
			<div class="page-panel">
				<div class="item">
					<div class="item-name">{{$t('liveplatform.home.pageText1-1')}}</div>
					<div class="item-box">{{panelBrowse.count?panelBrowse.count:0}}</div>
					<div class="item-text">{{panelBrowse.percent?panelBrowse.percent:0}}%</div>
				</div>
				<div class="item">
					<div class="item-name">{{$t('liveplatform.home.pageText1-2')}}</div>
					<div class="item-box">{{panelNewFans.count?panelNewFans.count:0}}</div>
					<div class="item-text">{{panelNewFans.percent?panelNewFans.percent:0}}%</div>
				</div>
				<div class="item">
					<div class="item-name">{{$t('liveplatform.home.pageText1-3')}}</div>
					<div class="item-box">{{panelGrass.count?panelGrass.count:0}}</div>
					<div class="item-text">{{panelGrass.percent?panelGrass.percent:0}}%</div>
				</div>
			</div>
			<div class="section-bottom">
				<router-link :to="{name: 'DataPerformance'}" class="more">{{ $t('liveplatform.home.more')}} <i class="el-icon-arrow-right"></i></router-link>
			</div>
		</div>
		
		<div class="page-section">
			<div class="section-top">
				<router-link :to="{name: 'LiveList'}" class="title">{{ $t('liveplatform.home.pageTitle2') }} <i class="el-icon-arrow-right"></i></router-link>
			</div>
			<div class="page-live">
				<div class="item" v-for="(item,index) in liveList" :key="index">
					<div class="item-main">
						<el-tag type="danger" size="mini" effect="dark" v-if="item.status==1">{{$t('liveplatform.list.tagText2')}}</el-tag>
						<el-tag type="info" size="mini" effect="dark" v-else-if="item.status==2">{{$t('liveplatform.list.tagText3')}}</el-tag>
						<el-tag size="mini" effect="dark" v-else>{{$t('liveplatform.list.tagText1')}}</el-tag>
						<span class="title">{{item.title}}</span>
					</div>
					<ul class="item-box">
						<li v-if="item.status==-1"><span class="red">{{$t('liveplatform.list.expired')}}</span></li>
						<li v-else><span>{{item.settime}}</span></li>
						<li><span>ID:{{item.live_id}}</span></li>
						<template v-if="item.status==-1">
							<li><el-button :disabled="true" type="info" size="mini" round>{{$t('liveplatform.list.infoBtn')}}</el-button></li>
							<li><el-button :disabled="true" type="danger" size="mini" round>{{$t('liveplatform.list.openBtn')}}</el-button></li>
						</template>
						<template v-else-if="item.status==0">
							<li><el-button type="info" @click="$router.push({name: 'LiveEdit',params: {id:item.live_id}})" size="mini" round>{{$t('liveplatform.list.infoBtn')}}</el-button></li>
							<li><el-button type="danger" @click="handleBeginLive(item.live_id)" size="mini" round>{{$t('liveplatform.list.openBtn')}}</el-button></li>
						</template>
						<template v-else-if="item.status==1">
							<!-- <li><el-button type="info" size="mini" round>{{$t('liveplatform.list.spreadBtn')}}</el-button></li> -->
							<li><el-button type="danger" @click="$router.push({name: 'LiveInfo',params: {id:item.live_id}})" size="mini" round>{{$t('liveplatform.list.infoBtn')}}</el-button></li>
						</template>
						<template v-else-if="item.status==2">
							<li><el-button type="info" @click="$router.push({name: 'LivePlayback',params: {id:item.live_id}})" size="mini" round>{{$t('liveplatform.list.seePlaybackBtn')}}</el-button></li>
							<!-- <li><el-button type="danger" size="mini" round>{{$t('liveplatform.list.dataBtn')}}</el-button></li> -->
						</template>
					</ul>
				</div>
			</div>
		</div>
		
		<!-- <div class="page-section">
			<div class="section-top">
				<span class="title">{{ $t('liveplatform.home.pageTitle3') }}</span>
			</div>
			<div class="page-menu">
				<div class="item">
					<img src="@/assets/images/liveplatform/icon1.png" alt="" class="pic">
					<div class="name">{{ $t('liveplatform.home.pageName3-1') }}</div>
					<div class="text">{{ $t('liveplatform.home.pageText3-1') }}</div>
				</div>
				<div class="item">
					<img src="@/assets/images/liveplatform/icon2.png" alt="" class="pic">
					<div class="name">{{ $t('liveplatform.home.pageName3-2') }}</div>
					<div class="text">{{ $t('liveplatform.home.pageText3-2') }}</div>
				</div>
				<div class="item">
					<img src="@/assets/images/liveplatform/icon3.png" alt="" class="pic">
					<div class="name">{{ $t('liveplatform.home.pageName3-3') }}</div>
					<div class="text">{{ $t('liveplatform.home.pageText3-3') }}</div>
				</div>
				<div class="item">
					<img src="@/assets/images/liveplatform/icon4.png" alt="" class="pic">
					<div class="name">{{ $t('liveplatform.home.pageName3-4') }}</div>
					<div class="text">{{ $t('liveplatform.home.pageText3-4') }}</div>
				</div>
				<div class="item">
					<img src="@/assets/images/liveplatform/icon5.png" alt="" class="pic">
					<div class="name">{{ $t('liveplatform.home.pageName3-5') }}</div>
					<div class="text">{{ $t('liveplatform.home.pageText3-5') }}</div>
				</div>
			</div>
		</div> -->
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import { fetchStatics, getLiveList, onBroadcast } from '@/api/liveplatform';
	export default {
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		data() {
			return {
				panelBrowse: {},
				panelNewFans: {},
				panelGrass: {},
				liveList: {},
			}
		},
		mounted() {
			this.getPanelData();
			this.getLiveList();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorUser;
				e.target.style.objectFit = 'fill';
			},
			getPanelData(){
				fetchStatics({app_key: this.$store.getters.appKey}).then(response => {
					this.panelBrowse = response.data.views;
					this.panelNewFans = response.data.new_fans;
					this.panelGrass = response.data.durings;
				})
			},
			getLiveList(){
				let param = {
					page_index:	0,
					page_size: 3,
					app_key: this.$store.getters.appKey
				}
				getLiveList(param).then(response => {
					this.liveList = response.data.list;
				})
			},
			handleBeginLive(id){
				this.$confirm(this.$t('liveplatform.confirmMsg.playText'), this.$t('liveplatform.confirmMsg.title'), {
					confirmButtonText: this.$t('liveplatform.confirmMsg.confirmBtn'),
					cancelButtonText: this.$t('liveplatform.confirmMsg.cancelBtn'),
					type: 'warning'
				}).then(() => {
					let param = {
						liveId:	id,
						uuid: this.$store.getters.uuid,
						app_key: this.$store.getters.appKey
					}
					onBroadcast().then(response => {
						this.$message.success(this.$t('liveplatform.successMsg.paly'));
						this.getLiveList();
					})
				}).catch(() => {
					this.$message(this.$t('liveplatform.cancelMsg.play'))
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.page-section {
		padding: 20px;
		border-radius: 6px;
		color: #FFFFFF;
		margin-bottom: 10px;
		background-color: $--live-background-default;
		.section-top {
			font-weight: 700;
			margin-bottom: 18px;
			.title {
				color: #FFFFFF;
				font-size: 16px;
			}
		}
		.section-bottom {
			text-align: right;
			line-height: 22px;
			.more {
				color: $--live-text-color-1;
				font-size: 14px;
			}
		}
	}
	.page-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.user-info {
			display: flex;
			align-items: center;
			.pic {
				width: 68px;
				height: 68px;
				border-radius: 50%;
				object-fit: cover;
				border: 1px solid #FFFFFF;
			}
			.info-main {
				margin-left: 12px;
				width: 160px;
				.name {
					font-size: 16px;
					color: #FFFFFF;
					height: 26px;
					line-height: 26px;
					margin-bottom: 6px;
				}
				.text {
					font-size: 12px;
					height: 18px;
					line-height: 18px;
					margin-bottom: 6px;
					span {
						margin-right: 10px;
					}
				}
			}
		}
		.bar {
			::v-deep .el-button--livebtn {
				color: #FFFFFF;
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:focus,&:hover {
					color: #FFFFFF;
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}
		}
	}
	.page-panel {
		display: flex;
		margin-bottom: 18px;
		justify-content: space-around;
		.item {
			text-align: center;
			.item-name {
				color: $--live-text-color-1;
				font-size: 14px;
				line-height: 22px;
				margin-bottom: 8px;
			}
			.item-box {
				margin-bottom: 8px;
				font-size: 30px;
				font-weight: 700;
				color: #FFFFFF;
				line-height: 48px;
			}
			.item-text {
				color: $--live-text-color-1;
				font-size: 14px;
				line-height: 22px;
			}
		}
	}
	.page-live {
		.item {
			padding: 10px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.item-main {
				::v-deep .el-tag {
					width: 58px;
					border: none;
					border-radius: 10px;
					line-height: 20px;
					text-align: center;
					margin-right: 6px;
					&.el-tag--dark {
						background: $--live-tag-dark-shading;
						&.el-tag--info {
							background: $--live-tag-info-shading;
						}
						&.el-tag--danger {
							background: $--live-tag-danger-shading;
						}
					}
				}
			}
			.item-box {
				display: flex;
				align-items: center;
				li {
					margin-left: 8px;
					display: inline-block;
					span {
						color: $--live-text-color-1;
						font-size: 14px;
						&.red {
							color: $--live-link-color-1;
						}
					}
				}
				::v-deep .el-button {
					&.el-button--info {
						color: $--live-button-info-font;
						border-color: $--live-button-info-border;
						background-color: transparent;
						&:focus,&:hover {
							color: $--live-button-primary-hover-font;
							border-color: $--live-button-primary-hover-border;
						}
					}
					&.el-button--danger {
						color: #FFFFFF;
						border-color: $--live-button-primary-border;
						background-color: $--live-button-primary-background;
						&:focus,&:hover {
							color: #FFFFFF;
							border-color: $--live-button-primary-hover-border;
							background-color: $--live-button-primary-hover-background;
						}
					}
					&.is-disabled {
						color: #595959;
						border-color: #595959;
						background-color: #262626;
						&:focus,&:hover {
							color: #595959;
							border-color: #595959;
							background-color: #262626;
						}
					}
				}
			}
		}
	}
	.page-menu {
		display: flex;
		justify-content: space-around;
		.item {
			text-align: center;
			cursor: pointer;
			.pic {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin-bottom: 10px;
			}
			.name {
				margin-bottom: 10px;
				line-height: 22px;
				color: #FFFFFF;
				font-size: 14px;
			}
			.text {
				line-height: 22px;
				color: $--live-text-color-1;
				font-size: 14px;
			}
		}
	}
</style>